import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Logo from "assets/logo/logo.png";
import "./style.css";

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const getActiveClass = (isActive) =>
    isActive
      ? "rounded transition-colors duration-300 text-gray-600 font-medium active"
      : "rounded transition-colors duration-300 text-black-700 font-medium";
  return (
    <div className="flex items-center justify-center transition-all duration-300 backdrop-blur-lg sticky top-0 z-[999]">
      <nav className="w-full shadow main-nav">
        <div className="sm:mx-4 sm:w-100 sm:max-w-screen-2xl md:mx-auto md:flex md:justify-between md:items-center">
          <div className="flex items-center justify-between py-3 md:py-3">
            <Link to={"/"}>
              <img
                src={Logo}
                alt="Logo"
                className="h-12 w-12 ml-2 md:ml-0 mr-0 md:mr-2"
              />
            </Link>
            <NavLink
              to={"/"}
              onClick={() => {
                setNavbarOpen(!navbarOpen);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="rounded transition-colors duration-300 text-black-700 md:ml-0 ml-2"
            >
              <h1 className="text-xl md:text-3xl font-semibold">
                B Tripathi & Associates
              </h1>
              <p className="text-md text-gray-800 font-medium md:text-xl md:text-end">
                Chartered Accountants
              </p>
            </NavLink>
            <div className="md:hidden">
              <button
                aria-label="menu"
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border hover:opacity-80"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                {navbarOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`flex items-center justify-between py-3 my-2 md:block md:p-0 md:m-0 ${
              navbarOpen ? "block shadow-top fade-in" : "hidden"
            }`}
          >
            <ul className="nav-ul items-center justify-center space-y-8 md:flex md:space-y-0">
              <li className="font-rubik">
                <HashLink
                  to={"/#about"}
                  onClick={() => setNavbarOpen(!navbarOpen)}
                  scroll={(el) =>
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    })
                  }
                  className={getActiveClass(window.location.hash === "#about")}
                >
                  About
                </HashLink>
              </li>
              <li className="font-rubik">
                <Menu
                  open={showSubMenu}
                  handler={setShowSubMenu}
                  allowHover
                >
                  <MenuHandler>
                    <HashLink
                      to={"/#services"}
                      onClick={() => setNavbarOpen(!navbarOpen)}
                      className="flex items-center gap-2 p-3 mx-3 text-base font-medium tracking-wide rounded transition-colors duration-300"
                      smooth
                    >
                      <div>Services</div>
                      <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`h-3.5 w-3.5 transition-transform ${
                          showSubMenu ? "rotate-180" : ""
                        }`}
                      />
                    </HashLink>
                  </MenuHandler>
                  <MenuList>
                    <MenuItem>
                      <NavLink
                        to={"/assurance-services"}
                        onClick={() => setNavbarOpen(!navbarOpen)}
                        className={({ isActive }) => getActiveClass(isActive)}
                      >
                        Assurance Services
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to={"/taxation-services"}
                        onClick={() => setNavbarOpen(!navbarOpen)}
                        className={({ isActive }) => getActiveClass(isActive)}
                      >
                        Taxation Services
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to={"/advisory-services"}
                        onClick={() => setNavbarOpen(!navbarOpen)}
                        className={({ isActive }) => getActiveClass(isActive)}
                      >
                        Advisory Services
                      </NavLink>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </li>
              <li className="font-rubik">
                <NavLink
                  to={"/clients"}
                  onClick={() => setNavbarOpen(!navbarOpen)}
                  className={({ isActive }) => getActiveClass(isActive)}
                >
                  Clients
                </NavLink>
              </li>
              <li className="font-rubik">
                <HashLink
                  to={"/#contact"}
                  onClick={() => setNavbarOpen(!navbarOpen)}
                  className={getActiveClass(
                    window.location.hash === "#contact"
                  )}
                  smooth
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
