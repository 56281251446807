import React from "react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

const Footer = () => {
  return (
    <>
      <footer className="relative w-full overflow-hidden bg-black text-white py-8 px-8 font-rubik">
        <div className="max-w-maxScreen mx-auto">
          <div className="text-base text-center">
            Copyright &copy; {new Date().getFullYear()} by B Tripathi &
            Associates, Chartered Accountants
          </div>
        </div>
      </footer>
      <a
        aria-label="whats-app"
        href="https://api.whatsapp.com/send?phone=919956449216"
        target="_blank"
        rel="noopener noreferrer"
        className="whats-app-pulse"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
    </>
  );
};

export default Footer;
