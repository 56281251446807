import Contact from "pages/Contact";

const Assurance = () => {
  return (
    <>
      <section className=" pb-10">
        <div className="pt-10">
          <h1 className="text-4xl text-center font-bold mt-6">
            Assurance Services
          </h1>
        </div>
        <hr className="border-b-1 border-gray-400 my-8 mx-auto w-3/4" />
        <div className="mx-auto w-3/4">
          <div className="flex flex-col items-center mb-4">
            <div className="text-lg leading-relaxed">
              In today's complex and dynamic business environment, ensuring the
              accuracy and reliability of financial and non-financial
              information is crucial for making informed decisions. Our
              Assurance Services are designed to provide stakeholders with
              confidence and peace of mind by delivering independent, objective
              assessments that help improve the quality and transparency of
              information.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Financial Statement Audit
            </p>
            <div className="text-lg leading-relaxed">
              Our comprehensive financial statement audits are designed to
              enhance the credibility of the financial information provided to
              stakeholders. We adhere to stringent auditing standards to ensure
              accuracy, compliance, and integrity in financial reporting.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">Internal Audit</p>
            <div className="text-lg leading-relaxed">
              We offer internal audit services that go beyond compliance. Our
              approach focuses on evaluating and improving the effectiveness of
              risk management, control, and governance processes to enhance your
              organization’s overall performance
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">Risk Assurance</p>
            <div className="text-lg leading-relaxed">
              Identifying and mitigating risks is essential for sustaining
              business growth. Our risk assurance services help you navigate
              through uncertainties by providing insights into potential risks
              and developing strategies to manage them effectively.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">Regulatory Compliance</p>
            <div className="text-lg leading-relaxed">
              Staying compliant with ever-changing regulations can be
              challenging. Our team ensures that your business meets all
              relevant regulatory requirements, reducing the risk of
              non-compliance and potential penalties.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Information Technology (IT) Assurance
            </p>
            <div className="text-lg leading-relaxed">
              In a digital world, IT systems are integral to business
              operations. Our IT assurance services ensure that your IT
              infrastructure is secure, reliable, and aligned with your business
              objectives. We assess your IT environment to identify
              vulnerabilities and recommend improvements.
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
                Why Choose Our Assurance Services?
              </h2>
            </div>
            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Expertise and Experience
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Our team of seasoned professionals brings a wealth of
                    experience across various industries, ensuring high-quality
                    and relevant assurance services.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Independence and Objectivity
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    As an independent third party, we provide unbiased and
                    objective assessments that stakeholders can trust.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Customized Solutions
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We understand that every business is unique. Our services
                    are tailored to meet your specific needs and challenges.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Commitment to Excellence
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We are committed to delivering exceptional service and
                    value, helping you achieve your business goals with
                    confidence.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
      <Contact />
    </>
  );
};

export default Assurance;
