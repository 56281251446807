import React from "react";

const Services = () => {
  return (
    <section id="services">
      <div className="pt-10 mt-10 box-border mx-4">
        <div className="bg-gray-100 py-10">
          <div className="text-center mb-10">
            <h2 className="text-4xl font-bold font-heebo">Services</h2>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/4 px-4 mb-8 lg:mb-0">
              <div className="bg-gray-800 h-full p-8 text-center rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <h3 className="mb-4 text-white font-heebo text-xl font-semibold">
                  Assurance Services
                </h3>
                <ul className="text-white text-left">
                  <li>Internal & Statutory audit</li>
                  <li>Accounting advisory services</li>
                  <li>Inventory Audits</li>
                  <li>Attestation function</li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/4 px-4 mb-8 lg:mb-0">
              <div className="bg-gray-600 h-full p-8 text-center rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <h3 className="mb-4 text-white font-heebo text-xl font-semibold">
                  Business Management Consultants
                </h3>
                <ul className="text-white text-left">
                  <li>IT, ITES, BPO, Aerospace Business Set Up Services</li>
                  <li>Business Consultancy and Transformation</li>
                  <li>Business Set up Services</li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/4 px-4 mb-8 lg:mb-0">
              <div className="bg-gray-800 h-full p-8 text-center rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <h3 className="mb-4 text-white font-heebo text-xl font-semibold">
                  Taxation Services
                </h3>
                <ul className="text-white text-left">
                  <li>Transfer Pricing</li>
                  <li>Executive Taxation Services</li>
                  <li>Goods and Service Tax - GST</li>
                  <li>Corporate Income Tax</li>
                  <li>Foreign Corporate Taxation in India</li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/4 px-4">
              <div className="bg-gray-600 h-full p-8 text-center rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <h3 className="mb-4 text-white font-heebo text-xl font-semibold">
                  Corporate Outsourcing Services
                </h3>
                <ul className="text-white text-left">
                  <li>Accounting & Book Keeping</li>
                  <li>Payroll Processing</li>
                  <li>Human Resource Consulting</li>
                  <li>STPI / SEZ Services</li>
                  <li>Business Process Outsourcing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
