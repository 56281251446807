import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";
import Home from "pages/Home";
import Clients from "pages/Clients";
import Assurance from "pages/Services/Assurance";
import Taxation from "pages/Services/Taxation";
import Advisory from "pages/Services/Advisory";

function Routers() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/assurance-services"
          element={<Assurance />}
        />
        <Route
          path="/taxation-services"
          element={<Taxation />}
        />
        <Route
          path="/advisory-services"
          element={<Advisory />}
        />
        <Route
          path="/clients"
          element={<Clients />}
        />
        <Route
          path="*"
          element={<Home />}
        />
      </Routes>
      <Footer />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default Routers;
