import React from "react";

const About = () => {
  return (
    <section id="about">
      <div className="flex flex-col justify-center max-w-4xl mx-4 md:mx-auto">
        <h1 className="font-semibold text-xl md:text-4xl mb-2">About Us</h1>
        <p className="text-justify mt-2">
          B Tripathi & Associates was established in the year 2017 by CA
          Bhoopendra Kumar Tripathi. We are empanelled with office of
          Comptroller & Auditor General of India (CAG) and Reserve Bank of
          India. We focused and specialized in the area of taxation, financial
          advisory and auditing.
        </p>
        <p className="text-justify mt-2">
          We have the knowledge and expertise in Internal Audit so client can
          rest assured that effective tax strategies will be planned for them.
          We keep our people updated with growing complexity of the Indian Tax
          system as well as international taxation.
        </p>
        <p className="text-justify mt-2">
          We have the necessary expertise to render a variety of professional
          services to clients all over India.
        </p>
        <p className="text-justify mt-2">
          We are using latest technologies to cater the various requirements of
          the nationwide clients from varied industries. We have dedicated teams
          for Audit & Assurance, International Taxation and Transfer Pricing,
          GST, Start-up advisory etc.
        </p>
      </div>
    </section>
  );
};

export default About;
