import React from "react";
import { Link } from "react-router-dom";
import Team2Img from "assets/images/team2.jpg";

const TeamManagement = () => {
  return (
    <section>
      <div className="container mx-auto p-12">
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between">
          <div className="lg:w-2/3 mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold mb-4">
              Our people make us great
            </h2>
            <p className="text-gray-700 mb-2">
              CA Bhoopendra Kumar Tripathi is qualified chartered accountant and
              holding diploma in Information system Audit from Institute of
              Chartered accountants of India and practicing since 2017. He also
              completed Certificate Course of Internal Audit from Institute of
              Chartered accountants of India
            </p>
            <p className="text-gray-700 mb-6">
              The high professional standards set by the CA Bhoopendra Kumar
              Tripathi in rendering a comprehensive range of services has helped
              him in becoming one of the leading practicing Chartered Accountant
              in the city.
            </p>
            <Link
              to="/team"
              className="items-center px-4 py-2 bg-black text-white rounded-lg shadow-md hover:bg-black-700 hidden"
            >
              View more
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L13.586 11H4a1 1 0 110-2h9.586l-3.293-3.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </div>
          <div className="lg:w-2/4 flex justify-center">
            <div className="relative bg-gray-100 rounded-lg p-4 shadow-lg w-80">
              <div className="overflow-hidden rounded-md">
                <img
                  src={Team2Img}
                  alt="Bhoopendra Kumar Tripathi"
                  className="w-full h-full"
                />
              </div>
              <div className="absolute inset-0 flex flex-col items-center justify-end text-white p-4 mb-4">
                <div className="text-xl font-bold">Bhoopendra Kumar Tripathi</div>
                <div className="text-gray-200">Founder</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamManagement;
