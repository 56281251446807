import about1 from "assets/images/about1.png";
import about3 from "assets/images/about3.png";
import about4 from "assets/images/about4.png";
import about5 from "assets/images/about5.png";

const Featured = () => {
  return (
    <section>
      <div className="pt-10 block box-border mx-auto max-w-4xl">
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0 text-center animate__animated animate__zoomIn">
            <div className="box-border">
              <img
                src={about1}
                alt=""
                className="max-w-full mx-auto mb-0 border-none"
              />
              <p className="mb-0 text-center mt-0">Audit and assurance</p>
            </div>
          </div>
          <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0 text-center animate__animated animate__zoomIn">
            <div className="box-border">
              <img
                src={about3}
                alt=""
                className="max-w-full mx-auto mb-0 border-none"
              />
              <p className="mb-0 text-center mt-0">
                Taxation advisory and representation
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0 text-center animate__animated animate__zoomIn">
            <div className="box-border">
              <img
                src={about4}
                alt=""
                className="max-w-full mx-auto mb-0 border-none"
              />
              <p className="mb-0 text-center mt-0">
                Investigations, inspections, and special audits
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/4 px-4 mb-8 sm:mb-0 text-center animate__animated animate__zoomIn">
            <div className="box-border">
              <img
                src={about5}
                alt=""
                className="max-w-full mx-auto mb-0 border-none"
              />
              <p className="mb-0 text-center mt-0 break-words">
                Specialized advisory services including Capital restructuring,
                mergers, demergers, Corporate & Allied Laws, Due Diligence,
                Corporate Financial Consulting, Project Finance
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Featured;
