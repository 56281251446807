import React from "react";
import Contact from "pages/Contact";

const Taxation = () => {
  return (
    <>
      <section className=" pb-10">
        <div className="pt-10">
          <h1 className="text-4xl text-center font-bold mt-6">
            Taxation Services
          </h1>
        </div>
        <hr className="border-b-1 border-gray-400 my-8 mx-auto w-3/4" />
        <div className="mx-auto w-3/4">
          <div className="flex flex-col items-center mb-4">
            <div className="text-lg leading-relaxed">
              Navigating the complexities of taxation requires expertise and
              precision. Our Taxation Services are designed to help individuals
              and businesses minimize their tax liabilities while ensuring
              compliance with the ever-changing tax laws and regulations. We
              provide a comprehensive range of services tailored to meet your
              specific needs, ensuring you receive the most effective tax
              strategies and solutions.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Tax Planning and Strategy
            </p>
            <div className="text-lg leading-relaxed">
              Effective tax planning is essential for financial success. We work
              closely with you to develop customized tax strategies that align
              with your financial goals. Our proactive approach ensures that you
              take advantage of all available tax-saving opportunities.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Tax Compliance and Reporting
            </p>
            <div className="text-lg leading-relaxed">
              Staying compliant with tax laws is crucial to avoid penalties and
              legal issues. We assist you in preparing and filing accurate tax
              returns, ensuring that all deadlines are met and that you comply
              with local, state, and federal tax regulations.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Corporate Tax Services
            </p>
            <div className="text-lg leading-relaxed">
              Our corporate tax services are designed to help businesses
              navigate the complexities of corporate taxation. We provide
              support in areas such as tax structuring, mergers and
              acquisitions, and international tax planning, ensuring your
              business remains tax-efficient and compliant.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">Personal Tax Services</p>
            <div className="text-lg leading-relaxed">
              For individuals, we offer personalized tax services to help manage
              your personal tax obligations. Whether you need assistance with
              income tax returns, estate planning, or retirement planning, our
              experts are here to guide you through the process and optimize
              your tax position.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Tax Resolution and Representation
            </p>
            <div className="text-lg leading-relaxed">
              Dealing with tax disputes or audits can be stressful. Our
              experienced tax professionals provide representation before tax
              authorities, helping to resolve issues efficiently and
              effectively. We work to protect your interests and achieve the
              best possible outcome.
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
                Why Choose Our Taxation Services?
              </h2>
            </div>
            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Expertise and Knowledge
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Our team of tax professionals stays up-to-date with the
                    latest tax laws and regulations, ensuring you receive
                    accurate and relevant advice.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Personalized Approach
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We understand that each client has unique tax needs. Our
                    services are tailored to provide the most effective
                    solutions for your specific situation.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Comprehensive Support
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    From tax planning to compliance and resolution, we offer a
                    full spectrum of tax services to meet all your needs.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Proactive Solutions
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We focus on proactive tax planning to help you minimize
                    liabilities and maximize savings, ensuring your financial
                    well-being.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
      <Contact />
    </>
  );
};

export default Taxation;
