import React from "react";
import ReactPlayer from "react-player";
import Video from "assets/videos/video.mp4";

const Contact = () => {
  return (
    <>
      <section className="video-content-bg">
        <ReactPlayer
          url={Video}
          playing={true}
          loop={true}
          muted={true}
          width={"100%"}
          height={"100%"}
          className="video-content-video"
        />
      </section>
      <section id="contact">
        <div className="mx-4 md:mx-32 mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="bg-white shadow-md rounded-lg p-0 md:p-6 mb-2">
              <iframe
                title="B Tripathi & Associates, Chartered Accountants"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3550.638721538889!2d81.95777199999999!3d27.136187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDA4JzEwLjMiTiA4McKwNTcnMjguMCJF!5e0!3m2!1sen!2sin!4v1718622592034!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{
                  border: 0,
                  width: "100%",
                  height: "450px",
                  borderRadius: "5px",
                }}
              ></iframe>
            </div>
            <div className="bg-white shadow-md rounded-lg p-0 md:p-6 mb-2">
              <div className="text-xl font-bold text-gray-800 mb-4">
                B Tripathi & Associates, Chartered Accountants
              </div>
              <div className="bg-gray-800 text-white p-4 rounded-md mb-4">
                <div className="flex items-center mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>
                  <span>Office :-</span>
                </div>
                <div className="ml-10">616, CIVIL LINES GONDA-271001</div>
              </div>
              <div className="bg-gray-800 text-white p-4 rounded-md mb-4">
                <div className="flex items-center mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                    />
                  </svg>
                  <span>Contact No. :-</span>
                </div>
                <div className="ml-10">+91 99564 49216</div>
              </div>
              <div className="bg-gray-800 text-white p-4 rounded-md mb-4">
                <div className="flex items-center mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                  <span>Email :-</span>
                </div>
                <div className="ml-10">btripathiandassociates@gmail.com</div>
              </div>
              <div className="bg-gray-800 text-white p-4 rounded-md mb-4">
                <div className="flex items-center mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <span>Working Hours :-</span>
                </div>
                <div className="ml-10">
                  Monday to Saturday: 10:30 AM to 5:30 PM
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
