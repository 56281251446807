import React from "react";
import Contact from "pages/Contact";
import punjabsindhBank from "assets/images/clients/punjabandsindhbank.png";
import indiabank from "assets/images/clients/indianbank.png";
import shemesh from "assets/images/clients/shemesh.png";
import apnicalss from "assets/images/clients/apniclass.png";
import aryavatbank from "assets/images/clients/aryavatbank.png";
import bankofindia from "assets/images/clients/bankofindia.png";
import bhabha from "assets/images/clients/bhabha.png";
import bupgp from "assets/images/clients/bupgb.png";
import bgi from "assets/images/clients/bgi.png";
import swapnilSoft from "assets/images/clients/swapnilSoft.png";

const Clients = () => {
  const ourClients = [
    {
      id: 1,
      image: punjabsindhBank,
      alt: "punjabsindhbank",
      height: 100,
      width: 100,
    },
    {
      id: 2,
      image: indiabank,
      alt: "indiabank",
      height: 200,
      width: 200,
    },
    {
      id: 3,
      image: aryavatbank,
      alt: "aryavatbank",
      height: 50,
      width: 80,
    },
    {
      id: 4,
      image: bankofindia,
      alt: "bankofindia",
      height: 200,
      width: 200,
    },
    {
      id: 5,
      image: bupgp,
      alt: "bupgp",
      height: 200,
      width: 200,
    },
    {
      id: 6,
      image: bhabha,
      alt: "bhabha",
      height: 200,
      width: 200,
    },
    {
      id: 7,
      image: shemesh,
      alt: "shemesh",
      height: 150,
      width: 150,
    },
    {
      id: 8,
      image: apnicalss,
      alt: "apnicalss",
      height: 200,
      width: 200,
    },
    {
      id: 9,
      image: bgi,
      alt: "bgi",
      height: 85,
      width: 85,
    },
    {
      id: 10,
      image: swapnilSoft,
      alt: "swapnilSoft",
      height: 200,
      width: 200,
    },
  ];

  return (
    <>
      <section>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
          <div className="mb-20 text-center">
            <h1 className="text-4xl text-gray-900 text-center font-bold">
              We work with the best partners
            </h1>
          </div>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">
            {ourClients.map((client) => (
              <div
                key={client.id}
                className="flex justify-center items-center border border-solid border-gray-200 shadow-sm h-24 rounded-2xl"
              >
                {client.name ? (
                  <p className="font-bold text-lg mx-8">{client.name}</p>
                ) : (
                  <img
                    src={client.image}
                    height={client.height}
                    width={client.width}
                    alt={client.alt}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <Contact />
    </>
  );
};

export default Clients;
