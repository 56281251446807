import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";
import About from "pages/About";
import Contact from "pages/Contact";
import Featured from "pages/Featured";
import Services from "pages/Services";
import TeamManagement from "pages/Team/TeamManagement";
import Banner1 from "assets/images/banner1.jpg";
import Banner2 from "assets/images/banner2.jpg";
import Banner3 from "assets/images/banner3.jpg";
import "./style.css";

const Home = () => {
  return (
    <>
      <section className="mb-10">
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="w-full h-[500px]"
        >
          <SwiperSlide>
            <img
              src={Banner1}
              alt=""
              className="w-full h-[500px] object-cover"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Banner2}
              alt=""
              className="w-full h-[500px] object-cover"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Banner3}
              alt=""
              className="w-full h-[500px] object-cover"
            />
          </SwiperSlide>
        </Swiper>
      </section>
      <About />
      <Featured />
      <Services />
      <TeamManagement />
      <Contact />
    </>
  );
};

export default Home;
