import React from "react";
import Contact from "pages/Contact";

const Advisory = () => {
  return (
    <>
      <section className=" pb-10">
        <div className="pt-10">
          <h1 className="text-4xl text-center font-bold mt-6">
            Advisory Services
          </h1>
        </div>
        <hr className="border-b-1 border-gray-400 my-8 mx-auto w-3/4" />
        <div className="mx-auto w-3/4">
          <div className="flex flex-col items-center mb-4">
            <div className="text-lg leading-relaxed">
              In an ever-changing business landscape, making informed decisions
              is crucial for achieving sustainable growth and competitive
              advantage. Our Advisory Services are designed to provide you with
              the strategic insights and expert guidance needed to navigate
              complex challenges and seize opportunities. We partner with you to
              deliver customized solutions that drive success and innovation.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Business Strategy and Planning
            </p>
            <div className="text-lg leading-relaxed">
              Our experts work with you to develop robust business strategies
              and comprehensive plans that align with your vision and goals. We
              help you identify opportunities for growth, optimize operations,
              and achieve long-term success.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">Financial Advisory</p>
            <div className="text-lg leading-relaxed">
              Sound financial management is the backbone of any successful
              organization. We provide a wide range of financial advisory
              services, including financial planning, budgeting, forecasting,
              and capital management, to enhance your financial health and
              performance.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">Risk Management</p>
            <div className="text-lg leading-relaxed">
              Effectively managing risk is essential for sustaining business
              growth. We assist you in identifying, assessing, and mitigating
              risks through tailored risk management strategies that safeguard
              your business and enhance resilience.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Operational Improvement
            </p>
            <div className="text-lg leading-relaxed">
              Streamlining operations can lead to significant efficiency gains
              and cost savings. Our advisory team analyzes your current
              processes and recommends improvements to enhance productivity,
              reduce waste, and optimize performance.
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <p className="font-semibold text-2xl mb-1">
              Mergers and Acquisitions (M&A)
            </p>
            <div className="text-lg leading-relaxed">
              Navigating the complexities of mergers and acquisitions requires
              expertise and strategic planning. We provide end-to-end support,
              from due diligence and valuation to integration and post-merger
              optimization, ensuring a smooth and successful transaction.
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
                Why Choose Our Advisory Services?
              </h2>
            </div>
            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Expert Insights
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Our team of seasoned advisors brings deep industry knowledge
                    and expertise, providing you with valuable insights and
                    practical solutions.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Tailored Solutions
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We recognize that every business is unique. Our advisory
                    services are customized to meet your specific needs and
                    objectives, ensuring relevant and effective outcomes.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Collaborative Approach
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We work closely with you, fostering a collaborative
                    partnership to understand your challenges and co-create
                    solutions that drive success.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Commitment to Excellence
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We are dedicated to delivering high-quality advisory
                    services that exceed your expectations and contribute to
                    your long-term success.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
      <Contact />
    </>
  );
};

export default Advisory;
